// regular style toast
@import 'ngx-toastr/toastr';

.toast-success {
    background-color: #004566 !important; /* Set the background color for success toast */
    color: #fff; /* Set the text color for success toast */
  }
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}


/* Firefox */
input[type=number] {
  -moz-appearance: textfield !important;
}
button.mat-focus-indicator.save-btn.mat-raised-button.mat-button-base.mat-button-disabled {
  background: #908bc5 !important;
}
// ::-webkit-scrollbar {
//   width: 6px;
// }

// ::-webkit-scrollbar-track {

//   background :#8f8a8a;
// }

::-webkit-scrollbar-thumb {
  border-radius: 5px ;

}
.error-text{
  font-size: 10px !important;
  color: red;
}
// .mat-icon-button {
//   line-height: 30px;
  
  
// }
.custom-dialog .icon-close {
  font-size: 15px; /* Adjust the font size to your desired value */
}

[hidden] {
  display: none !important;
}
.mat-icon-button {
 
  width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;

}
.table{
  margin-bottom: 0px !important;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 4px 4px 0 0;
  padding: 0.55em 0.75em 0 0.75em !important;
}
// .ellipsis-container {
//   /* Set the width or max-width of the container to control where the text should be truncated */
//   width: 80px !important;
//   /* Add some styling for demonstration purposes */
//   border: 1px solid #ccc  !important;
//   // padding: 10px;
// }

// .ellipsis-text {
//   /* Apply ellipsis properties */
//   white-space: nowrap  !important;
//   overflow: hidden  !important;
//   text-overflow: ellipsis  !important;
// }
// td.mat-cell span.ellipsis-text {
//   /* Apply ellipsis properties */
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   /* Add any other styling as needed */
// }
.description{
  white-space: nowrap!important;
  width: 160px!important;
  overflow: hidden!important;
  text-overflow: ellipsis!important;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // display: -webkit-box;
  // -webkit-line-clamp: 1 !important;
  // width: 100px !important;
  // -webkit-box-orient: vertical;
}

::ng-deep .mat-select-value {
  position: relative;
}

/* Show the 'x' icon as a pseudo-element inside the mat-select */
::ng-deep .mat-select-value::after {
  content: 'x';
  position: absolute;
  right: 8px; /* Adjust the position as needed */
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #999; /* Change the color as needed */
}

/* Hide the 'x' icon when no option is selected */
::ng-deep .mat-select-disabled .mat-select-value::after {
  display: none;
}
.mat-select-arrow {
  border: none;
 
}
.bgheight{
  height: 100vh;
}
.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: translateY(0%)  !important;
}
﻿/*
 *  Document   : _general.scss
 *  Author     : RedStar Template
 *  Description: This scss file for all common style classes
 */

//  input[type="file"]{
//   visibility: hidden; 
// }
.invalid-feedback{
  display: block !important;
  text-align: left !important;
  font-size: 10px !important;
}
.icon-color{
 color: #004566 !important;
}

.mat-mdc-icon-button{
  --mat-mdc-button-persistent-ripple-color: none !important;
  --mat-mdc-button-ripple-color: none !important;
}
.mat-mdc-focus-indicator {
  background: none !important;
}
:ng-deep .mat-icon-button {
  color: inherit;  background: none !important;
}
.image-preview{
  height: 5rem;
  margin:1rem 1rem;
}
// .subcatetable{
//   height: 400px;
//   width: 1100px;
//   overflow: auto !important;
// }
//  mathesh-start

.back-icon{
  margin-top:7px;
  margin-left: 0 !important ;
}
.back-icon-1{
  margin-top:13px;
  margin-left: 0 !important ;
}

//partner
//table
.partner-t-td{
  border-right:1px solid #ffff !important;
}
//table-finish
//partner-finish

//popup delete
.cancel-delete{
  display: flex;
  justify-content: space-evenly;
}

//mathesh-finish
.mat-dialog-container {
  border-radius: 15px !important;
  border: 2px solid #003149 !important;
  padding: 15px 24px 0px 24px !important;
}

.image-preview img{
  height: 100%;
}
.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, width .2s ease-in-out;
  -webkit-transition: background-color .2s linear, width .2s ease-in-out;
  width: 3px !important;
  // right: 2px;
  right: 0px !important;
  position: absolute;
}

body.light {
  background-color: #f0f3fb;
}
.mat-form-field {
  display: inline-block;
  position: relative;
  text-align: left;
  width: 100% !important;
}

body,
html {
  @include transition(all 0.5s);
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}
input,
select,
a {
width: 100% !important;

}
button,
input,
select,
a {
  outline: none !important;
  font-size: 14px !important;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

textarea {
  font-size: 14px !important;
}

.row {
  margin-bottom: 0px !important;
}

ol,
ul,
dl {
  padding-left: 0px;
  list-style-type: none;
}

.no-animate {
  -o-transition-property: none !important;
  -moz-transition-property: none !important;
  -ms-transition-property: none !important;
  -webkit-transition-property: none !important;
  transition-property: none !important;
  -o-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -webkit-transform: none !important;
  transform: none !important;
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
}

section {
  &.content {
    margin: 35px 8px 0 260px;
    min-height: calc(100vh - 76px);
    @include transition(0.5s);
    .content-block {
     padding: 25px 0px 0px 0px !important;
    }
  }
}
.banner-btn{
  background: #004566 !important;
  color: white !important;
  font-size: 16px;
}
.banner-reset-btn{
  background: #004566 !important;
  color: white !important;
  margin-left:5px ;
  font-size: 16px;
}
.horizontal-layout {
  section {
    &.content {
      margin: 170px 15px 0 15px;
      float: left;
      width: calc(100% - 30px);
    }
  }
}

table {
  width: 100%;
}

.img-item {
  width: 30px;
  height: 30px;
}
.table-heading{
  background: #004566 !important;
  color: white !important;
  border-right: 1px solid white !important;
  text-align: center !important;
}

.edit{
  color: red;
  font-size: 17px;
}
.delete-icon{
  color: red;
  font-size: 20px;
}
.eye-icon{
  font-size: 19px;
}
.table-responsive{
border-radius: 10px !important;
}
.tableBody {
  padding: 0px 0px 0px 0px !important;
}
td{
  font-size: 12px;
}
.table-data{
  font-size: 12px !important;
  text-align: center !important;
  border-right:  1px  solid #eee !important;
  // border-right: 1px solid #F2F2F2 !important;
}
.tabledata-leftalign{
  font-size: 12px !important;
  border-right:  0.980198px solid #eee !important; 
}
.category-name{
  border-bottom: 1px solid rgba(255, 255, 255, 0.6) !important;
}
.cancel-btn{
  background: #BBBBBB !important;
border: 1px solid #525252;
font-size: 14px;
padding: 1px 25px !important;
}
.save-btn{
  background: #004566 !important;
  color: white !important;
  font-size: 14px;
  padding: 1px 25px !important;
}
.confirm-btn{
  background: #004566 !important;
  color: white !important;
  font-size: 14px;
  padding: 1px 25px !important; 
}
// .table{
//   margin-bottom: 0 rem !important;
// }

// .table-container {
//   overflow-x: auto;
//   overflow-y: hidden;
// }
// order details

.day-info p{
  font-weight:bold;
  color: black;
  font-size: 17px;

}
.day-date{
  font-size: 14px;

}
.day-status{
  justify-content: space-between;
}
.ikon-sec span{
  // margin-top: 2px;
  font-weight: bold;
  font-size: 17px;
}
.address-ikon{
  font-size: 25px;
    color: #004566;
    margin-right:7px;
    
}
.orginal-address{
  margin-left: 6%;
}
.orginal-address{
  color:rgba(0, 0, 0, 0.6980392157)
}
.order-input{
  padding:5px !important;
}
.orderform-label{
  font-weight:bold;
}
.order-table{
  background-color: white !important;
  color: black !important;
}
.mat-form-field-wrapper.ng-tns-c142-0 {
  margin: 0 !important;
}
.mat-form-field-wrapper.ng-tns-c142-3 {
  margin: 0 !important;
}
.mat-form-field-flex.ng-tns-c142-2 {
  border: none;
}

// order details end










.start{
  background: #004566;
    min-height: 8vh;
  margin:0;
  padding:0;
}
.coloumn-1{
  background: #4b6875;
min-height:100vh;
}
::placeholder{
  color:#D9D9DF;

}
.tab-head{
  background-color: #004566;
}
.input-search{
  padding:7px;  
  border:1px solid #B6B6BB;
  outline:none;
  width:100%;

}
.save button{
  background-color: #004566;
  font-weight:bold;
  color:#fff;
  width:100%;
}
.file-drop-area {
  border: 1px dashed #7c7db3 !important;
  border-radius: 3px;
  position: relative;
  width: 100% !important;
  margin-top: 5px;
  padding: 26px 20px 30px;
  transition: .2s;
}
.mat-form-field-appearance-fill .mat-form-field-flex {
  background: #FFFFFF !important;
  border: 1px solid #B6B6BB !important;
  border-radius: 4px;
}


.edit{
  color:#5A5A5F;
  font-size: 15px;
    margin-top: 6px;
  }
.delete-icon{
color:#FF0C0C
  }
  // .edit .mat-icon{
  //   font-size: 15px;
  //   margin-top: 6px;
  // }
.textarea-sec{
  width: 100% !important;
  margin-bottom: 0.2em;

}

// upload

.hoverable{
  position: relative;
  display: block;
  cursor: pointer;
  height: 200px;
  width: 200px;
  border: 5px solid #000;
  border-radius: 50%;
}
.hoverable .hover-text{
  position: absolute;
  display: none;
  top: 50%;
  left:  50%;
  transform: translate(-50%,-50%);   
  z-index: 2;
}
.hoverable .background{
  position: absolute;
  display: none;
  top: 0;
  left:  0;
  bottom: 0;
  right: 0;
  background-color:rgba(255, 255, 255, 0.5);
  pointer-events: none;
  border-radius: 50%;
  z-index: 1;
}
.hoverable:hover .hover-text{
  display: block;
}
.hoverable:hover .background{
  display: block;
}

#fileInput{
  display: none;
}

.file-img{
  width: 60px !important;
 }

// end upload

// product-list-2

.product-heading{
  color: #004566;
  font-weight: bold;
  font-size: 19px;
}
.product-content{
  font-size: 18px;
  color:black;
}



// product-list-2-finish

.text-color{
  color: #004566 !important;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center !important;
}
.add-item{
  text-align: center;
  // justify-content: center;
  display: flex;
  align-items: center !important;
}




  //////
  
//sub-categories

.mat-elevation-z4 {
  box-shadow: none;
 }
.mat-tab-labels {
  display: block !important;
}
.input-search {
  margin-left: 0px !important;
}
.mat-tab-labels {
  background: #F4F4F4;
}
.mat-tab-body-content {
  /* height: 100%; */
  overflow: hidden !important;
}

table {
  width: 100%;
}
.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: none;
}
tr.mat-mdc-header-row.mdc-data-table__header-row.cdk-header-row {
  background: #004566;
}
.table-head{
  color:white;
}
.pencil-icon{
  color:#5A5A5F;
  font-size:15px !important;
}
.delete-icon{
  color:#FF0C0C;
}

//tabs
.mat-elevation-z4,
.mat-mdc-elevation-specific.mat-elevation-z4 {
    box-shadow: none;
}
::ng-deep .mat-mdc-tab-list {
    flex-grow: 0 !important;
}
::ng-deep.mat-mdc-tab-header {
    background-color: #F4F4F4;
}
::ng-deep.mat-mdc-tab .mdc-tab__text-label,
.mat-mdc-tab-link .mdc-tab__text-label {
    color: black;
}
//forminput
.input-search{
    padding:7px;  
    border:1px solid #B6B6BB;
    outline:none;
    width:100%;
}
//savebutton
.save button {
    background-color: #004566;
    font-weight: bold;
    color: #fff;
    padding: 10px 5px;
}
//icon
.edit {
    color: #5A5A5F;
}
.delete-icon {
    color: #FF0C0C;
    cursor: pointer !important;
}
.edit .mat-icon {
    font-size: 18px;
    margin-top: 6px;
    cursor: pointer !important;
}
::ng-deep.mat-mdc-tab-body-content {
    height: 100%; 
    overflow:hidden !important;
 }


// ::ng-deep.table-sm>:not(caption)>*>* {
// padding: 0;
// }




//sub-category-1


// .main-table-2{
// min-width:60%;
// }


/////////

table {
    width: 100%;
    padding:50px !important;
}
.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
    box-shadow: none;
}
tr.mat-mdc-header-row.mdc-data-table__header-row.cdk-header-row {
    background: #004566;
}
.table-head{
    color:white;
}
// -- search--
.search-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  // max-width: 500px;
  border: 1px solid #E0E0E0;
}

.form-field {
  min-width: 60px;
  flex: 1 auto;
}
// -- search end--
.mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 4px 4px 0 0;
  padding: 0.75em 0.75em 0 0.75em !important;
}
// date picker & search
.mat-form-field-appearance-fill .mat-form-field-flex {
  padding: 0 0 0 8px !important;
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0;
}
 input#mat-input-0 {
  padding: 2px;
 }
// input#mat-input-0 {
//   padding: 12px;
// }
// input.mat-input-element {
//   margin-top: 1.5em !important;
// }

.mat-form-field-underline.ng-tns-c142-2.ng-star-inserted {
  display: none !important;
}
.mat-form-field-underline.ng-tns-c142-3.ng-star-inserted {
  display: none !important;
}
.form-details .mat-form-field-wrapper {
  padding-bottom: 0em !important;
}
.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  // padding-bottom: 0px !important;
  padding: 0 0 0 9px !important;
}
.mat-form-field-underline.ng-tns-c142-13.ng-star-inserted {
  display: none !important;
}
// date picker end and search


//order

.day-info{
  font-weight:bold;
  font-size: 17px;
}
.day-info p{
  font-weight: 400;
    color: #000000B2;
}
.day-status{
  justify-content: space-between;
}
.ikon-sec span{
  // margin-top: 2px;
  font-weight: bold;
  font-size: 17px;
}
.address-ikon{
  font-size: 25px;
    color: #004566;
}
.orginal-address{
  margin-left: 6%;
}
.orginal-address{
  color:rgba(0, 0, 0, 0.6980392157)
}
.order-input{
  padding:5px !important;
}
.orderform-label{
  font-weight:bold;
}
.order-table{
  background-color: white !important;
  color: black !important;
}


//order-finish

//genrate-report

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 16px 0 11px 0;
}
.mat-form-field-underline.ng-tns-c142-5.ng-star-inserted {
  display: none;
}
.mat-form-field-underline.ng-tns-c142-4.ng-star-inserted {
  display: none;
}
.mat-form-field-infix {
  border-top: 0;
}
.generatereport-button button{
  background:#004566;
  color:#ffff;
  font-weight: bold;
}

th.mat-header-cell.cdk-header-cell.table-heading.cdk-column-No6.mat-column-No6.ng-star-inserted {
  padding: 0 !important;
}

.user-type{
  margin-top: 3px;
}
input.mat-autocomplete-trigger.ng-untouched.ng-pristine.ng-valid {
  padding: 8px;
  border: 1px solid gainsboro;
  margin-top: 3px;
  border-radius: 5px;
}
.mat-form-field-underline.ng-tns-c142-6.ng-star-inserted{
  display: none;
}
.mat-form-field-underline.ng-tns-c142-7.ng-star-inserted {
  display: none;
}

//generate-report-finish


//dash-board

.progress-card{
  background-color: #004566;
}
// .mat-form-field-infix.ng-tns-c142-2 {
//   display: flex;
// }
// .mat-form-field-infix.ng-tns-c142-0 {
//   display: flex;
// }
.mat-form-field-infix.ng-tns-c141-0 {
  display: flex;
}


.most p{
  color:#fff;
  font-size: 16px;
  font-weight: 600;
}
.most-fromdatepicker{
  justify-content: space-between;
}
.mat-date-range-input-container {
  margin-top: 0px;
  justify-content: space-between;

  // margin-top:12px;
}
.item-content{
  justify-content: space-between;
}
.whole-item{
  color:#ffff;
  font-weight: 500;
}
.progress-bar-style{
  border-radius: 5px;
}
.card-pie{
  background-color: #004566;
}
.customer-pie{
  color:#ffff;
  font-weight: bold;
  font-size: 18px;
}
.piechart-content-1{
  font-weight: bold;
}
.piechart-content-2{
  font-weight:400;
  justify-content: space-around;
  color:#ffff;
}
//dash-board end










.app-file-upload {
  padding: 24px;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #004566 !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #00456680 !important;
}

.rotate-90 {
  transform: rotate(90deg);
}


.pull-left {
  float: left !important;
}

.pull-right {
  float: right !important;
}

.msl-1 {
  margin-left: 0.25rem !important;
}
.msl-2 {
  margin-left: 0.5rem !important;
}
.msl-3 {
  margin-left: 1rem !important;
}
.msl-4 {
  margin-left: 1.5rem !important;
}
.msl-5 {
  margin-left: 3rem !important;
}

.msr-1 {
  margin-right: 0.25rem !important;
}
.msr-2 {
  margin-right: 0.5rem !important;
}
.msr-3 {
  margin-right: 1rem !important;
}
.msr-4 {
  margin-right: 1.5rem !important;
}
.msr-5 {
  margin-right: 3rem !important;
}

.psl-1 {
  padding-left: 0.25rem !important;
}
.psl-2 {
  padding-left: 0.5rem !important;
}
.psl-3 {
  padding-left: 1rem !important;
}
.psl-4 {
  padding-left: 1.5rem !important;
}
.psl-5 {
  padding-left: 3rem !important;
}

.psr-1 {
  padding-right: 0.25rem !important;
}
.psr-2 {
  padding-right: 0.5rem !important;
}
.psr-3 {
  padding-right: 1rem !important;
}
.psr-4 {
  padding-right: 1.5rem !important;
}
.psr-5 {
  padding-right: 3rem !important;
}
.cursor-pointer {
  cursor: pointer;
}

.jqvmap-zoomin,
.jqvmap-zoomout {
  width: 15px;
  height: 15px;
}

table {
  .checkbox {
    [type='checkbox'] + label {
      margin: 0;
      height: 20px;
      padding-left: 20px;
      vertical-align: middle;
    }
  }
}

.loading-img-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin: -60px 0 20px -20px;
  -webkit-animation: spin 1.5s linear infinite;
  -moz-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

.shadow-style {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.review-img {
  padding-left: 20px;
  padding-top: 5px;
  width: 70px;

  img {
    border-radius: 50%;
    border: 2px solid #ffffff;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  }
}

.horizontal-layout {
  .sidebar,
  .nav-left-menu {
    display: none;
  }
}

.bootstrap-notify-container {
  max-width: 320px;
  text-align: center;
}

.map iframe {
  width: 100%;
}

.jqvmap-label {
  position: absolute;
  display: none;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background: #eee;
  color: black;
  font-size: 14px;
  font-family: sans-serif, Verdana;
  padding: 10px;
  pointer-events: none;
}

.logo-white {
  .navbar-toggle,
  .bars:before,
  .bars:after {
    color: #000 !important;
  }
}

.logo-black {
  .navbar-toggle,
  .bars:before,
  .bars:after {
    color: #fff !important;
  }
}

.dark {
  .sidemenu-collapse i {
    color: #fff;
  }

  .nav {
    > li {
      > a {
        color: #fff;
      }
    }
  }
}

.light {
  .sidemenu-collapse i {
    color: #0d091d;
  }

  .nav {
    > li {
      > a {
        color: #0d091d;
      }
    }
  }
}

.border-apply {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2) !important;
}

input::-webkit-input-placeholder {
  font-size: 12px;
  color: #adabab;
}

input:-moz-placeholder {
  /* Firefox 18- */
  font-size: 12px;
  color: #adabab;
}

input::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 12px;
  color: #adabab;
}

input:-ms-input-placeholder {
  font-size: 12px;
  color: #adabab;
}

textarea::-webkit-input-placeholder {
  font-size: 12px;
  color: #adabab;
}

textarea:-moz-placeholder {
  /* Firefox 18- */
  font-size: 12px;
  color: #adabab;
}

textarea::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 12px;
  color: #adabab;
}

textarea:-ms-input-placeholder {
  font-size: 12px;
  color: #adabab;
}

.profile-image img {
  width: 100%;
}

@media screen and (max-width: 1169px) {
  .horizontal-layout {
    .sidebar {
      display: block;
    }

    .top-sidebar {
      display: none;
    }

    section.content {
      margin-top: 100px;
    }
  }
}

.font-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  transition: all 0.2s;

  .icon-preview {
    font-size: 1.8rem;
    margin-right: 10px;
    line-height: 1;
    color: #333439;
  }
}

.deshboard-echart-height {
  height: 250px;
}
.pill-style {
  font-size: 17px;
  color: #a9a6a6;
  padding-right: 5px;
}
.pill-timing {
  width: 30%;
}
.dot1 {
  color: #5a5faf;
}
.dot2 {
  color: #f7bf31;
}
.dot3 {
  color: #ea6e6c;
}
.dot4 {
  color: #28bdb8;
}
@media screen and (max-width: 1169px) {
  .horizontal-layout {
    .sidebar {
      display: block;
    }

    .top-sidebar {
      display: none;
    }

    section.content {
      margin-top: 100px;
    }
  }
}

@media screen and (min-width: 1400px) {
  .boxed-layout .container {
    width: 1370px;
    max-width: 100%;
  }
}
